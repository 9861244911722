import React from 'react'
import Layout from '../../components/layout'
import { StaticQuery, graphql } from 'gatsby'

import Nav from '../../components/en/nav'

import Divider from '../../images/divider.png'

const HotWaterPage = () => (
  <StaticQuery
    query={graphql`
      query ENHotWaterQuery {
        wordpressPage(wordpress_id: { eq: 197 }) {
          acf {
            english
            circle_image {
              source_url
            }
            en_products {
              product_image {
                source_url
              }
              product_name
              product_description
              buttons {
                button_link
                button_label
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Nav />
        <div className="app-main body-main">
          <div
            className="top-image"
            style={{
              backgroundImage: `url(${Divider})`,
            }}
          >
            <img
              alt="armstrong"
              className="circle"
              src={data.wordpressPage.acf.circle_image.source_url}
            />
          </div>
          <h1>Tailored Hot Water System Solutions For Healthcare</h1>
          <div
            dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.english }}
          />
          {data.wordpressPage.acf.en_products.map(repeater => (
            <div className="product" key={repeater.product_name}>
              <img alt="divider" src={Divider} />
              <img
                alt="product"
                className="circle"
                src={repeater.product_image.source_url}
              />
              <h2>{repeater.product_name}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: repeater.product_description,
                }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: repeater.buttons.button_label,
                }}
              />
              {repeater.buttons.map(buttonRepeater => (
                <a
                  key={buttonRepeater.button_link}
                  className="btn"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={buttonRepeater.button_link}
                >
                  {buttonRepeater.button_label}
                </a>
              ))}
            </div>
          ))}
        </div>
      </Layout>
    )}
  />
)

export default HotWaterPage
